import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dropdown = ({
  options,
  onChange,
  value,
  label,
  noSelectFirst,
  disabled,
  disabledText,
}) => {
  let selected = options.find((item) => item.id === value) || options[0];
  if (noSelectFirst) {
    selected = null;
  }

  return (
    <Listbox disabled={disabled} value={value} onChange={onChange}>
      {({ open }) =>
        disabled ? (
          <div className="bg-gray-100 border rounded text-gray-600 text-sm font-medium py-2 px-4 border-gray-200">
            {disabledText}
          </div>
        ) : (
          <>
            <Listbox.Label className="sr-only block text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <span className="block truncate">{selected?.value}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 scrollbar-thin scrollbar-thumb-indigo-700 scrollbar-track-indigo-300 cursor-pointer mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options?.map((person) => (
                    <Listbox.Option
                      key={person.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block"
                            )}
                          >
                            {person.value}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )
      }
    </Listbox>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string.isRequired,
};

Dropdown.defaultProps = {
  options: [{}],
};
