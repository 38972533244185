export const CANCELLED = "cancelled";

export const CONFIRMED = "confirmed";

export const PENDING = "pending";

export const WAITING_LIST = "waiting_list";

const USER_STATUS = {
  CONFIRMED,
  CANCELLED,
  PENDING,
  WAITING_LIST,
};

const ORG_STATUS = {
  CONFIRMED,
  PENDING,
  CANCELLED,
  WAITING_LIST,
};

const STATUS_FIELDS = {
  user: "userStatus",
  org: "orgStatus",
};

export { USER_STATUS, ORG_STATUS, STATUS_FIELDS };
