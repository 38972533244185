import { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  where,
  getDocs,
  query,
} from "firebase/firestore";

const db = getFirestore();

export default function useCollectionQuery(
  collectionPath,
  whereOptions,
  onlyRunIf
) {
  const [docs, setDocs] = useState(null);

  const collRef = collection(db, collectionPath);
  const fetchDocs = async () => {
    if (collectionPath) {
      try {
        const listQuery = query(
          collRef,
          ...whereOptions.map((opt) => where(...opt))
        );
        const listDocs = await getDocs(listQuery);
        const listData = listDocs.docs.map((list) => ({
          ...list.data(),
          _id: list.id,
        }));
        setDocs(listData);
      } catch (err) {
        console.warn("error with, ", collectionPath, whereOptions);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (onlyRunIf || onlyRunIf === undefined) {
      fetchDocs();
    }
  }, [collectionPath, whereOptions.length, onlyRunIf]);

  return docs;
}
