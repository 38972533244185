export const formatTime = (obj) => (
  <>
    {obj.start?.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    })}
    {` - `}
    {obj.end?.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    })}
  </>
);

export const formatDate = (obj) =>
  obj.start?.toLocaleDateString("en-GB", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
