import { useState, useEffect } from "react";
import { doc, getDoc, getFirestore, collection } from "firebase/firestore";

export default function useDocFetch(collectionId, docId) {
  const [details, setDetails] = useState(null);
  const fetchDoc = async () => {
    if (collectionId && docId) {
      try {
        const db = getFirestore();
        const collRef = collection(db, collectionId);
        const docRef = doc(collRef, docId);
        const result = await getDoc(docRef);
        const data = { ...result.data(), _id: docId };
        setDetails(data);
      } catch (err) {
        console.warn("error with, ", collectionId, docId);
        console.error(err);
      }
    }
  };

  useEffect(() => {
    fetchDoc();
  }, [collectionId, docId]);

  return details;
}
