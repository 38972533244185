import React, { useContext, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import UserContext from "./UserContext";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
);

/*
  1. Create an order document with the values from Booking (and the memberIds?)
  2. Send to checkout
  3. Set up webhook to respond to fulfillment - read from order collection to create bookings
  4. Return to url with order ID in URL
  5. Read order document, if is complete, jump to "finished" state, with values from the order document.
  6. If not complete, fill in values and pre-fill potential guest data

*/

export default function Checkout({
  items,
  orderId,
  orgStripeAccountId,
  isProgramme,
  description,
  eventData,
  eventId: eventIdProp,
  text,
  orgId: orgIdProp,
  payingOutstanding,
  orderIds,
  bookingIds,
  paymentSalesIds,
  disabled,
  paymentMethod,
}) {
  const { idToken, refreshToken } = useContext(UserContext);

  useEffect(() => {
    refreshToken();
  }, []);

  const generateEndpoint = () => {
    if (paymentMethod === "bank-transfer") {
      return `/api/invoice`;
    } else if (isProgramme) {
      return `/api/checkout_programme_sessions`;
    } else if (payingOutstanding) {
      return `/api/checkout_outstanding`;
    } else {
      return `/api/checkout_sessions`;
    }
  };

  const orgId = orgIdProp || eventData?.managedBy;
  const eventId = eventIdProp || eventData?._id;

  console.log({ items });

  return (
    <div className="w-full ">
      <form action={generateEndpoint()} method="POST" className="w-full">
        <input
          type="hidden"
          id="items"
          name="items"
          value={JSON.stringify(items)}
        />
        <input
          type="hidden"
          id="description"
          name="description"
          value={description || eventData?.name?.text}
        />
        <input type="hidden" id="orderId" name="orderId" value={orderId} />
        <input
          type="hidden"
          id="idToken"
          name="idToken"
          value={idToken?.token}
        />
        {orgId && <input type="hidden" id="orgId" name="orgId" value={orgId} />}
        {eventId && (
          <input type="hidden" id="eventId" name="eventId" value={eventId} />
        )}
        {payingOutstanding && (
          <input
            type="hidden"
            id="payingOutstanding"
            name="payingOutstanding"
            value={payingOutstanding}
          />
        )}
        {paymentSalesIds && (
          <input
            type="hidden"
            id="paymentSalesIds"
            name="paymentSalesIds"
            value={paymentSalesIds}
          />
        )}
        {orderIds && (
          <input type="hidden" id="orderIds" name="orderIds" value={orderIds} />
        )}
        {bookingIds && (
          <input
            type="hidden"
            id="bookingIds"
            name="bookingIds"
            value={bookingIds}
          />
        )}
        <input
          type="hidden"
          id="orgStripeAccountId"
          name="orgStripeAccountId"
          value={orgStripeAccountId}
        />
        <section className="w-full">
          <button
            className={`
              ${
                disabled
                  ? "bg-gray-300  text-gray-600 border-gray-200 cursor-not-allowed"
                  : `bg-accent  border-transparent rounded-md shadow-sm  text-primary hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500`
              } w-full border rounded py-3 px-4 text-base font-medium`}
            type="submit"
            role="link"
            disabled={disabled}
          >
            {text || "Checkout"}
          </button>
        </section>
      </form>
    </div>
  );
}
