import { useState, useEffect, useContext } from "react";
import UserContext from "./UserContext";

const MultiplePeople = ({ values, onChange, handleSave }) => {
  const maxAttendees = Math.max(...Object.values(values));

  const [responses, setResponses] = useState([]);

  useEffect(() => {
    const arrFromObj = Object.keys(responses).reduce(
      (arr, key) => {
        console.log({ arr });
        arr[Number(key)] = responses[key];
        console.log({ arr });
        return arr;
      },
      [{}]
    );
    onChange(arrFromObj);
  }, [responses]);

  const { user } = useContext(UserContext);

  return (
    <div>
      <h1 className="p-2 border-b border-gray-200 text-md text-center w-full">
        Other Attendees
      </h1>
      <div
        style={{ maxHeight: "calc(100vh - 180px)" }}
        className="p-4  pb-8 space-y-4 overflow-y-scroll"
      >
        {[...Array(maxAttendees).keys()].map((i) => (
          <div key={i}>
            <h2 className="font-medium text-2xl pb-4 pt-4">
              Attendee {i + 1}: Details
            </h2>
            <form className="space-y-2">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
                <span className="italic font-normal text-red-600">*</span>
              </label>
              <input
                defaultValue={i === 0 ? user?.displayName : ""}
                type="text"
                disabled={i === 0}
                name="name"
                onChange={(e) =>
                  setResponses({
                    ...responses,
                    [i]: { ...responses[i], name: e.target.value },
                  })
                }
                placeholder="Name*"
                className={`${
                  i === 0 ? "cursor-not-allowed bg-gray-100 text-gray-600" : ""
                } mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md max-w-lg`}
              />

              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
                <span className="italic font-normal text-red-600">*</span>
              </label>
              <input
                type="email"
                disabled={i === 0}
                onChange={(e) =>
                  setResponses({
                    ...responses,
                    [i]: { ...responses[i], email: e.target.value },
                  })
                }
                defaultValue={i === 0 ? user?.email : ""}
                name="email"
                placeholder="Email*"
                className={`${
                  i === 0 ? "cursor-not-allowed bg-gray-100 text-gray-600" : ""
                } mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md max-w-lg`}
              />
            </form>
          </div>
        ))}
      </div>
      <div className="p-4 bg-gray-50">
        <button
          onClick={handleSave}
          type="submit"
          className={`bg-accent text-primary w-full border border-transparent rounded-md py-2 flex items-center 
              justify-center text-md font-medium hover:shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default MultiplePeople;
